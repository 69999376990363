import React, { useEffect } from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { useI18next } from "gatsby-plugin-react-i18next";
import { getAlternateLang } from "../utils/alternate-lang";
import PrivateRoute from "../components/private-route";
import Brick from "../components/bricks/bricks";
import { Container } from "@mui/material";
import { isFullLength } from "../utils";
import { gtmPushUserInfo } from "../services/auth";

export const query = graphql`
  query AboutUs($language: String!) {
    strapiAboutUsEn: strapiAboutUs(locale: { eq: "en" }) {
      pageContent
      pageContentConstrainedImages {
        localFile {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED)
          }
        }
      }
      pageContentFullWidthImages {
        localFile {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
    strapiAboutUsEs: strapiAboutUs(locale: { eq: "es-PA" }) {
      pageContent
      pageContentConstrainedImages {
        localFile {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED)
          }
        }
      }
      pageContentFullWidthImages {
        localFile {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

const AboutUsComponent = ({ data }) => {
  const { t, language } = useI18next();

  useEffect(() => {
    window.dataLayer?.push({
      event: "page_info",
      page_language: language,
      page_category: "About_Us",
      page_ta: "",
      page_type: "",
    });
    gtmPushUserInfo();
  }, [language]);
  const aboutUs =
    language === "en" ? data.strapiAboutUsEn : data.strapiAboutUsEs;
  const getImageContent = (i) => {
    return aboutUs.pageContentFullWidthImages[i]?.localFile !== null
      ? aboutUs.pageContentFullWidthImages[i]
      : aboutUs.pageContentConstrainedImages[i];
  };
  return (
    <Layout>
      <SEO
        seo={{
          metaTitle: t("metaData.aboutUs.metaTitle"),
          metaDescription: t("metaData.aboutUs.metaDescription"),
          linkAlternateHref: "/" + getAlternateLang(language) + "/about-us",
          linkHrefLang: getAlternateLang(language),
          noindex: true,
        }}
      />
      {aboutUs.pageContent.map((content, i) => (
        <Container
          style={{
            paddingLeft: 0,
            paddingRight: 0,
          }}
          maxWidth={isFullLength(content) ? false : "lg"}
        >
          <Brick
            key={`content__${i}`}
            content={content}
            imageContent={getImageContent(i)}
          />
        </Container>
      ))}
    </Layout>
  );
};

const AboutUs = ({ data }) => {
  return <PrivateRoute component={AboutUsComponent} data={data} />;
};

export default AboutUs;
